import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
  ImgPlacement,
  InfoTitle,
  Space,
  Text,
  TextFontWeight,
  TextSize,
} from '@dist-property-frontend/toolkit';
import { checkZeroResults } from '../helpers/filtersZeroResults';
import { FilterRequestAPI } from './FilterTypes';
import styled from 'styled-components';

export const FiltersZeroResults = ({
  filterAPI,
  onResetFilter,
}: {
  filterAPI: FilterRequestAPI[];
  onResetFilter: (filter: FilterRequestAPI) => void;
}) => {
  //get the active filters and display a button with CTA to reset the filter from search
  const activeFilters = checkZeroResults(filterAPI).filter(
    (filter) => filter !== null,
  );

  return (
    <Container>
      <InfoTitle
        img={
          <img
            src={'/static/images/empty-question-mark.svg'}
            alt="Empty results"
          />
        }
        imgPlacement={ImgPlacement.TOP}
        heading={
          <Text as="p" size={TextSize.L18} fontWeight={TextFontWeight.SEMIBOLD}>
            Try removing some filters or searching in more areas
          </Text>
        }
      />
      <Space space={'M16'} />
      <ButtonsContainer>
        {activeFilters?.map((filter) => (
          <Button
            key={filter.displayName}
            buttonSize={ButtonSizes.X_SMALL}
            buttonType={ButtonTypes.SECONDARY}
            onClick={() => onResetFilter(filter)}
            Icon={<Icon icon="CLOSE" size={16} />}
            data-testid={`zero-results-btn-${filter.displayName}`}
            useLegacyMinWidth={false}
          >
            {filter.displayName}
          </Button>
        ))}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme.toolkit.spacing.M16};
  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.xlarge}) {
    padding-left: ${({ theme }) => theme.toolkit.spacing.XL80};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.toolkit.spacing.S12};
`;
